export default [
  {
    id: "4",
    name: "Dog Boarding",
    serviceType: {
      id: "1",
      alwaysPickUpDropOff: false,
      fullDayAvailability: true,
      pricePer: "night",
      routeServiceParam: "dog-boarding"
    }
  },
  {
    id: "5",
    name: "Dog Walking",
    serviceType: {
      id: "2",
      alwaysPickUpDropOff: true,
      fullDayAvailability: false,
      pricePer: "hour",
      routeServiceParam: "dog-walking"
    }
  },
  {
    id: "6",
    name: "Doggy Day Care",
    serviceType: {
      id: "7",
      alwaysPickUpDropOff: false,
      fullDayAvailability: true,
      pricePer: "day",
      routeServiceParam: "dog-daycare"
    }
  },
  {
    id: "7",
    name: "House Sitting",
    serviceType: {
      id: "3",
      alwaysPickUpDropOff: false,
      fullDayAvailability: true,
      pricePer: "day",
      routeServiceParam: "house-sitting"
    }
  }
];
