import { useContext } from "react";
import { Flex, Box, Image } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";

import { Heading } from "../common";
import { LocaleContext } from "../locale";

const features = {
  gb: [
    "/images/home/press/bbc.png",
    "/images/home/press/metro.png",
    "/images/home/press/daily-mail.png",
    "/images/home/press/the-telegraph.png",
    "/images/home/press/forbes.png",
    "/images/home/press/financial-times.png"
  ],
  de: [
    "/images/home/press/forbes.png",
    "/images/home/press/techcrunch.png",
    "/images/home/press/moz-de.png",
    "/images/home/press/gruenderfreunde.png",
    "/images/home/press/deutsche-startups.png"
  ],
  es: [
    "/images/home/press/el-mundo.png",
    "/images/home/press/forbes.png",
    "/images/home/press/antena-3.png",
    "/images/home/press/el-pais.png",
    "/images/home/press/la-sexta.png",
    "/images/home/press/el-confidencial.png",
    "/images/home/press/techcrunch.png"
  ],
  fr: [
    "/images/home/press/forbes.png",
    "/images/home/press/financial-times.png",
    "/images/home/press/techcrunch.png",
    "/images/home/press/actu.png",
    "/images/home/press/frenchweb.png"
  ],
  ie: [
    "/images/home/press/lovin-dublin.png",
    "/images/home/press/forbes.png",
    "/images/home/press/financial-times.png",
    "/images/home/press/techcrunch.png",
    "/images/home/press/the-irish-times.png",
    "/images/home/press/daily-mail.png",
    "/images/home/press/independent-ie.png",
    "/images/home/press/joe.png"
  ],
  dk: [],
  no: [],
  se: []
};

export default function FeaturedIn({
  showTitle = true
}: {
  showTitle?: boolean;
}) {
  const { country } = useContext(LocaleContext);
  const { t } = useTranslation("home");

  const currentCountryFeatures = country ? features[country] : [];

  if (currentCountryFeatures.length === 0) return null;

  return (
    <Box variant="featuredIn.mainBox">
      {showTitle && (
        <Heading as="p" variant="featuredIn.heading" mb={1}>
          {t("featuredIn")}:
        </Heading>
      )}

      <Flex variant="featuredIn.flex">
        {features[country]?.map((val, index) => {
          return (
            // https://dev.to/felixhaeberle/responsive-fix-for-the-next-js-image-component-1351
            <Box key={index} variant="featuredIn.boxImg">
              <Image
                src={val}
                layout="fill"
                className="featured-in-image"
                alt={t("featuredIn")}
              />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
