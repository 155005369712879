import { Flex, Box, Text } from "rebass/styled-components";
import { Link as RebassLink } from "rebass/styled-components";
import Image from "next/image";
import { Heading, Button } from "../common";
import React, { useContext } from "react";
import useTranslation from "next-translate/useTranslation";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { LocaleContext } from "./../locale";

const BLOG_QUERY = gql`
  query getBlog {
    blog(page: 1, perPage: 3) {
      url
      posts {
        link
        excerpt
        title
        featuredMedia
      }
    }
  }
`;

const FeaturedMedia = ({ alt, src }: { alt: string; src: string }) => {
  return (
    <Box
      sx={{
        mb: 2,
        width: "100%",
        height: "200px",
        position: "relative",
        img: {
          borderRadius: "double"
        }
      }}
    >
      <Image src={src} alt={alt} title={alt} layout="fill" objectFit="cover" />
    </Box>
  );
};

export default function Blog() {
  const { t } = useTranslation("home");
  const { locale } = useContext(LocaleContext);

  const { data } = useQuery(BLOG_QUERY);

  if (locale == "de" || !data?.blog?.posts?.length) {
    return null;
  }
  return (
    <>
      <Flex
        flexWrap="wrap"
        variant="layout.large"
        py={[5, 5, 7]}
        width={1}
        flexDirection="column"
        sx={{
          borderBottom: "default"
        }}
      >
        <Box width={1}>
          <Heading as="h2" variant="h2">
            {t("gudogBlog")}
          </Heading>
        </Box>
        <Flex mx={-2} flexDirection={["column", "row"]}>
          {data?.blog?.posts?.map((post, index) => {
            return (
              <Box
                width={[1, 1 / 3, 1 / 3]}
                py={3}
                px={2}
                key={index}
                sx={{
                  position: "relative",
                  color: "veryDarkGray"
                }}
              >
                <a
                  href={post.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={post.title}
                >
                  <FeaturedMedia alt={post.title} src={post.featuredMedia} />
                </a>
                <Heading
                  as="h3"
                  variant="highlight"
                  sx={{
                    pb: 1
                  }}
                >
                  <RebassLink
                    href={post.link}
                    color="labradorBlack"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={post.title}
                  >
                    {post.title}
                  </RebassLink>
                </Heading>
                <Text as="p" pb={1}>
                  {post.excerpt}
                </Text>
                <Box sx={{ color: "springGreen" }}>
                  <RebassLink
                    variant="secondaryLink"
                    href={post.link}
                    target="_blank"
                    sx={{
                      bottom: 0,
                      position: "absolute",
                      fontSize: 3,
                      fontWeight: "book"
                    }}
                  >
                    {t("readMore")}
                  </RebassLink>
                </Box>
              </Box>
            );
          })}
        </Flex>
        <Box width={[1, 400, 300]} pt={4} mx={"auto"}>
          {data?.blog && (
            <Button width={1} variant="terciary">
              <Box
                as="a"
                href={data.blog.url}
                target="blank"
                sx={{
                  color: "labradorBlack",
                  textDecoration: "none"
                }}
              >
                {t("seeAllPosts")}
              </Box>
            </Button>
          )}
        </Box>
      </Flex>
    </>
  );
}
