import { Box, Image, Text } from "rebass/styled-components";

function SearchBanner({ country }: { country: string }) {
  let bannerText = "";
  switch (country) {
    case "dk":
      bannerText =
        "Dogley er nu en del af Gudog-familien! Du kan logge ind med dit Dogley login.";
      break;
    case "no":
      bannerText = "Du kan logge inn med din Dogley-pålogging.";
      break;
    case "se":
      bannerText =
        "Dogley är nu en del av Gudog-familjen! Du kan logga in med din Dogley-inloggning.";
      break;
  }

  return (
    <Box variant="searchBanner">
      <Image
        src={"/images/home/dogley.webp"}
        alt="Dogley Banner"
        width={142}
        height={44}
      />
      <Text mt="12px">{bannerText}</Text>
    </Box>
  );
}

export default SearchBanner;
