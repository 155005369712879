import { Flex, Box } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import { Heading } from "../common";
import { Feature } from "./Steps";

export default function About() {
  const { t } = useTranslation("home");
  return (
    <Box variant="layout.large" pb={[5, 5, 7]}>
      <Heading as="h2" variant="h2" pb={3}>
        {t("whyGudog")}
      </Heading>
      <Flex
        flexWrap="wrap"
        alignItems="baseline"
        justifyContent="space-between"
        mx={-2}
      >
        <Feature
          icon="verified"
          title={t("benefitsForDogs")}
          descriptionList={[
            t("benefitsForDogsDescOne"),
            t("benefitsForDogsDescTwo"),
            t("benefitsForDogsDescThree")
          ]}
        />
        <Feature
          icon="heart"
          title={t("benefitsForYou")}
          descriptionList={[
            t("benefitsForYouDescOne"),
            t("benefitsForYouDescTwo"),
            t("benefitsForYouDescThree")
          ]}
        />
        <Feature
          icon="veterinary"
          title={t("peaceOfMind")}
          descriptionList={[
            t("peaceOfMindDescOne"),
            t("peaceOfMindDescTwo"),
            t("peaceOfMindDescThree")
          ]}
        />
      </Flex>
    </Box>
  );
}
