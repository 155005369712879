import { Flex, Box, Text } from "rebass/styled-components";
import { useState } from "react";
import { OutsideClickHandler, ServiceIcon, Input } from ".";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

interface Props {
  initial?: number;
  services: number[];
  onClickHandler: (id: number) => void;
  label?: string;
  placeholder?: string;
  [x: string]: any;
}

const ServiceSelect = ({
  initial,
  services,
  onClickHandler,
  label,
  placeholder,
  sx,
  ...rest
}: Props) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(initial);

  const { t } = useTranslation("common");

  const serviceIcons = {
    1: "boarding",
    2: "dog-walking",
    7: "day-care"
  };

  return (
    <Box
      width={1}
      sx={{
        position: "relative",
        cursor: "pointer",
        ...sx
      }}
      onClick={() => {
        setActive(!active);
      }}
      {...rest}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setActive(false);
        }}
        disabled={!active}
      >
        {active && (
          <Flex
            flexWrap="wrap"
            sx={{
              backgroundColor: "samoyedWhite",
              position: "absolute",
              left: 0,
              right: 0,
              top: 8,
              paddingTop: 2,
              zIndex: 1,
              border: "default",
              borderBottomLeftRadius: "default",
              borderBottomRightRadius: "default",
              cursor: "pointer"
            }}
          >
            {services.map(id => (
              <Flex
                key={id}
                width={1}
                padding={1}
                alignItems="center"
                onClick={() => {
                  setSelected(id);
                  setActive(!active);
                  onClickHandler(id);
                }}
              >
                <Box as="span" fontSize={6}>
                  <ServiceIcon serviceTypeId={id} color="springGreen" />
                </Box>
                <Box ml={1}>
                  <Trans i18nKey={`common:serviceType${id}`} />
                </Box>
              </Flex>
            ))}
          </Flex>
        )}
        {!!label && (
          <Text
            as="label"
            htmlFor="services"
            sx={{ display: "block", marginBottom: 1 }}
          >
            <Trans i18nKey={label} />
          </Text>
        )}
        <Input
          as="input"
          id="services"
          value={selected ? t(`serviceType${selected}`) : ""}
          icon={selected ? `services/${serviceIcons[selected]}` : null}
          iconRight="down"
          iconColor="springGreen"
          placeholder={placeholder || null}
          sx={{
            zIndex: 1,
            position: "relative",
            pointerEvents: "none",
            paddingTop: 0,
            borderColor: active ? "springGreen" : "gray",
            "+i": {
              fontSize: 6,
              top: 1,
              left: 1,
              opacity: 1,
              zIndex: 1
            }
          }}
          onChange={() => {}}
        />
      </OutsideClickHandler>
    </Box>
  );
};

ServiceSelect.defaultProps = {
  initial: 0,
  label: "common:service"
};

export default ServiceSelect;
