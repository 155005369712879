import { Flex, Box, Text } from "rebass/styled-components";
import { Heading, ServiceIcon } from "../common";
import countryServiceTypes from "../../stories/fixtures/countryServiceTypes";
import useTranslation from "next-translate/useTranslation";

import Link from "../common/Link";
import { useContext } from "react";
import { LocaleContext } from "../locale";

export default function Services() {
  const { t } = useTranslation("home");
  const { country } = useContext(LocaleContext);

  const isNordicCountry = country && ["se", "dk", "no"].includes(country);

  return (
    <Box variant="layout.large" py={[5, 5, 7]}>
      <Heading as="h2" variant="h2" pb={3}>
        {t("gudogServices")}
      </Heading>
      <Flex
        flexWrap="wrap"
        mx={-2}
        sx={{
          position: "relative"
        }}
      >
        {countryServiceTypes.map((countryServiceType, index) => {
          // TODO - move this to CountryServiceType logic from API 
          if (countryServiceType.serviceType.id === "3" && !isNordicCountry)
            return null;

          return (
            <Box
              pb={[3, 3, 3]}
              width={[1, isNordicCountry ? 1 / 4 : 1 / 3]}
              px={2}
              key={index}
            >
              <Flex
                key={countryServiceType.id}
                alignItems="center"
                sx={{
                  color: "springGreen",
                  fontWeight: "normal"
                }}
              >
                <Flex
                  mr={2}
                  p={1}
                  variant="serviceIcon.home"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 7
                  }}
                >
                  <ServiceIcon
                    serviceTypeId={countryServiceType.serviceType.id}
                    color="springGreen"
                  />
                </Flex>
              </Flex>
              <Heading as="h3" variant="h3" mt={2}>
                {t(`serviceType` + countryServiceType.serviceType.id)}
              </Heading>
              <Text as="p" mt={2} mb={1}>
                {t(`serviceTypeDesc` + countryServiceType.serviceType.id)}
              </Text>
              <Link
                href={{
                  pathname: "/search/service",
                  query: {
                    service:
                      process.env.serviceRoutes[country][
                        countryServiceType.serviceType.routeServiceParam
                      ]
                  }
                }}
                title={t(`serviceType` + countryServiceType.serviceType.id)}
              >
                <Text
                  color="springGreen"
                  sx={{
                    position: ["static", "absolute"],
                    fontWeight: "book",
                    fontSize: 3,
                    bottom: "0"
                  }}
                >
                  {t("searchNow")}
                </Text>
              </Link>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
