
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Layout from "../components/Layout";
import Head from "next/head";
import Hero from "../components/Hero";
import Search from "../components/home/Search";
import About from "../components/home/About";
import { Flex, Box, Text } from "rebass/styled-components";
import Review from "../components/home/Review";
import Services from "../components/home/Services";
import useTranslation from "next-translate/useTranslation";

import MobileApp from "../components/home/MobileApp";
import FeaturedIn from "../components/home/FeaturedIn";
import Blog from "../components/home/Blog";
import { useIsMobile, withDeviceType } from "../lib/media";
import { SearchProvider } from "../components/search/SearchContext";
import publicRuntimeConfig from "../lib/public-runtime-config";
import Heading from "../components/common/Heading";
import Steps from "../components/home/Steps";
import Faq from "../components/home/Faq";
import {
  GetCountryServiceTypesDocument,
  useGetCountryServiceTypesQuery
} from "../generated/graphql";
import { addApolloState, initializeApollo } from "../lib/apolloClient";
import SearchBanner from "../components/home/SearchBanner";
import { useContext } from "react";
import { LocaleContext } from "../components/locale";
import theme from "../lib/theme";
import locationRedirect from "../lib/location-redirect";

interface Props {
  feed: [];
  query: Record<string, any>;
  deviceType?: string;
  host: string;
}

function Home(props: Props) {
  const { query, deviceType } = props;
  const { t } = useTranslation("home");

  const isMobileViewport = useIsMobile(deviceType);
  const { loading, data } = useGetCountryServiceTypesQuery();
  const countryServiceTypes = loading
    ? []
    : data && data.country.countryServiceTypes;

  const hrefLangAlternate = publicRuntimeConfig.hrefLangAlternate as any;

  // FOR THE DOGLEY BANNER
  const { country: countryCtx } = useContext(LocaleContext);
  const country = countryCtx || ("" as string);
  const showBanner = ["dk", "no", "se"].includes(country);
  const heroVariant = showBanner ? "banner" : "home";

  return (
    <Layout
      withTransparentHeader
      title={t("home:title")}
      displayBannerHome
      deviceType={deviceType}
    >
      <Head>
        {hrefLangAlternate.map(({ href, locale }, index) => {
          return (
            <link key={index} rel="alternate" href={href} hrefLang={locale} />
          );
        })}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://${props.host}",
              "logo": "https://${props.host}/images/logo.svg"
            }`
          }}
        />
      </Head>
      <Flex>
        <SearchProvider>
          <Box>
            <Hero
              variant={heroVariant}
              imgsrc="/images/home/hero.jpg"
              imgAlt={t("home:findTrustedSitters")}
              darken={0}
            >
              {isMobileViewport && (
                <Flex flexWrap="wrap" sx={{ alignSelf: "flex-end", p: 2 }}>
                  <Text as="h1" variant="text.h2" color="samoyedWhite">
                    {t("home:findTrustedSitters")}
                  </Text>
                  <Text
                    as="h2"
                    mt={2}
                    fontSize={4}
                    fontWeight={"book"}
                    color="samoyedWhite"
                  >
                    {t("home:bestAlternativeToKennels")}
                  </Text>
                </Flex>
              )}

              {!isMobileViewport && (
                <Flex variant="bannerFlex">
                  <Flex
                    variant="searchBox"
                    sx={showBanner && theme.variants.searchBoxWithBanner}
                  >
                    <Heading as="h1" variant="h2" color="labradorBlack">
                      {t("home:findTrustedSitters")}
                    </Heading>
                    <Text
                      variant="h4_highlight"
                      as="h2"
                      fontWeight={"book"}
                      mt={1}
                    >
                      {t("home:bestAlternativeToKennels")}
                    </Text>
                    <Search
                      query={query}
                      countryServiceTypes={countryServiceTypes}
                      isMobileViewport={false}
                    />
                  </Flex>

                  {showBanner && <SearchBanner country={country} />}
                </Flex>
              )}
            </Hero>

            <Box>
              {isMobileViewport && (
                <Box p={2} pb={0}>
                  <Search
                    query={query}
                    countryServiceTypes={countryServiceTypes}
                    isMobileViewport={true}
                  />
                </Box>
              )}
              <FeaturedIn />
              <Steps />
              <About />
              <Review />
              <Services />
              <MobileApp mobile={isMobileViewport} />
              <Faq />
              <Blog />
            </Box>
          </Box>
        </SearchProvider>
      </Flex>
    </Layout>
  );
}

 const _getServerSideProps = withDeviceType()(
  async ({ locale, req, res }) => {
    // locationRedirect(req, res);

    const apolloClient = initializeApollo(locale);

    await apolloClient.query({
      query: GetCountryServiceTypesDocument
    });

    return addApolloState(apolloClient, {
      props: {
        host: req.headers.host,
        locale
      }
    });
  }
);

export default Home;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  