import { Image, Flex, Box } from "rebass/styled-components";
import { Heading } from "../common";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { LocaleContext } from "../locale";

interface Props {
  mobile: boolean;
  backgroundColor?: string;
  title?: string;
  description?: string;
  demoImage1?: string;
  demoImage2?: string;
}

export default function MobileApp({
  mobile,
  backgroundColor = "inuOrange",
  title,
  description,
  demoImage1,
  demoImage2
}: Props) {
  const { t } = useTranslation("home");
  const { locale, country } = useContext(LocaleContext);

  const language = (locale ?? "en").substring(0, 2);
  return (
    <Flex
      flexWrap="wrap"
      alignItems={["center", "center"]}
      variant="layout.large"
      pt={[5, 5, "74px"]}
      width={1}
      sx={{
        backgroundColor,
        backgroundSize: "cover",
        color: "samoyedWhite",
        overflow: "hidden"
      }}
    >
      <Box
        width={[1, 1 / 2, 2 / 5]}
        pr={[0, 0, 4]}
        sx={{
          textAlign: "left"
        }}
      >
        <Heading as="h2" variant="h2">
          {title || t("gudogOnYourPhoneHeading")}
        </Heading>
        <Heading
          as="p"
          variant="h"
          fontWeight="book"
          pt={2}
          pb={3}
          sx={{ whiteSpace: "pre-line" }}
        >
          {description || t("gudogOnYourPhoneDesc")}
        </Heading>
        <Flex
          width={[1, 1, 1, 1, 3 / 5]}
          pb={[5, 5, "74px"]}
          justifyContent={["center", "flex-start"]}
        >
          {mobile ? (
            <>
              <Box width={1 / 2} mr={2}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.gudog.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image
                    alt="Gudog - Google Play"
                    src={`/images/home/${language}/google_play.svg`}
                    width={["100%", "100%", "100%", "auto"]}
                    height={["auto", "auto", "auto", "49.5px"]}
                  />
                </a>
              </Box>
              <Box width={1 / 2}>
                <a
                  href={`https://apps.apple.com/${country}/app/gudog/id964470204`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image
                    alt="Gudog - AppStore"
                    src={`/images/home/${language}/app_store.svg`}
                    width={["100%", "100%", "100%", "100%"]}
                    height={["auto", "auto", "auto", "49.5px"]}
                  />
                </a>
              </Box>
            </>
          ) : (
            <Image
              alt="Gudog App QR code"
              padding={1}
              backgroundColor="samoyedWhite"
              src="/images/home/mobile-qr.png"
              width={"auto"}
              height={"auto"}
            />
          )}
        </Flex>
      </Box>
      <Box
        flexGrow={1}
        width={[1, 1 / 2, 3 / 5]}
        pt={[5, 5, 0]}
        sx={{
          alignSelf: "flex-end",
          textAlign: "right",
          position: "relative",
          "&::after": {
            content: "''",
            display: "block",
            paddingBottom: ["75%", "100%", "100%", "85%", "60%"]
          }
        }}
      >
        <Image
          alt="Gudog App demo 2"
          src={demoImage2 || `/images/home/${language}/mobile_2.png`}
          layout="fill"
          sx={{
            verticalAlign: "bottom",
            maxWidth: ["65%", "55%", "55%", "40%", "35%"],
            right: ["initial", "48%", "45%", "38%", "28%"],
            left: ["-5%", "initial"],
            position: ["absolute", "absolute", "absolute", "abolsute"],
            bottom: ["-55%", "-10%", "-26%", "-16%", "-26%"]
          }}
        />
        <Image
          alt="Gudog App demo 1"
          src={demoImage1 || `/images/home/${language}/mobile_1.png`}
          layout="fill"
          sx={{
            verticalAlign: "bottom",
            maxWidth: ["65%", "75%", "65%", "50%", "40%"],
            right: ["-1%", 0],
            position: ["absolute", "absolute", "absolute", "abolsute"],
            bottom: ["-40%", "-30%", "-30%", "-25%", "-30%"]
          }}
        />
      </Box>
    </Flex>
  );
}
