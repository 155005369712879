import { Box, Text, Flex } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";

import { Button, Rating } from "../common";
import SearchBox from "../search/SearchBox";
import {
  useSearchState,
  useSearchDispatch,
  stateToQueryParams
} from "../search/SearchContext";
import DatesFilter from "../search/DatesFilter";

import ServiceSelect from "../common/ServiceSelect";
import { CountryServiceType } from "../../interfaces";
import { pushRoute } from "../../lib/routing";

interface Props {
  query: Record<string, any>;
  countryServiceTypes: CountryServiceType[];
  isMobileViewport: boolean;
}

export default function Search(props: Props) {
  const { countryServiceTypes, isMobileViewport } = props;
  const { t } = useTranslation("home");
  const searchState = useSearchState();
  const searchDispatch = useSearchDispatch();

  const serviceSelectHandler = id => {
    countryServiceTypes.map(countryServiceType => {
      if (countryServiceType.serviceType.id === id) {
        searchDispatch({
          type: "changeCountryServiceType",
          payload: {
            countryServiceType: countryServiceType
          }
        });
      }
    });
  };

  const submit = () => {
    pushRoute({ pathname: "/search", search: stateToQueryParams(searchState) });
  };

  return (
    <Box sx={{ "-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)" }}>
      <ServiceSelect
        label={t("imLookingFor")}
        placeholder={t("selectService")}
        sx={{ zIndex: 3 }}
        mt={[0, 3]}
        onClickHandler={serviceSelectHandler}
        services={countryServiceTypes.map(({ serviceType }) => serviceType.id)}
      />
      <Box pt={2}>
        <SearchBox
          icon="marker"
          label={t("where")}
          placeholder={t("postcodeOrAddress")}
        />
      </Box>
      <DatesFilter isMobileViewport={isMobileViewport} renderInputs={true} />
      <Button mt={1} variant="primary" size="large" width={1} onClick={submit}>
        {t("search")}
      </Button>

      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        mt={2}
        pb={[2, 0]}
      >
        <Rating score={5}></Rating>
        <Text variant="h4_highlight" ml={1} sx={{ color: "darkGray" }}>
          <Text as="span" sx={{ fontSize: 3, color: "labradorBlack" }} mr={1}>
            5.0
          </Text>
          +100k reviews
        </Text>
      </Flex>
    </Box>
  );
}
