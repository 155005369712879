import { Box, Flex, Text } from "rebass/styled-components";
import { Icon, CoverImage, Heading } from "../common";
import { Carousel } from "react-responsive-carousel";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { LocaleContext } from "../locale";
import { useContext } from "react";
import ReviewStars from "../common/ReviewStars";

export default function Review() {
  const reviews = {
    gb: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ],
    ie: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/ie/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/ie/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ],
    es: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/es/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/es/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ],
    fr: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/fr/home/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/fr/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ],
    de: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/de/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/de/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      },
      {
        hero: "/images/home/review_hero_3.jpg",
        avatar: "/images/home/de/review_1.svg",
        title: "reviewTitle3",
        review: "reviewDesc3"
      }
    ],
    dk: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ],
    se: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ],
    no: [
      {
        hero: "/images/home/review_hero_1.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle1",
        review: "reviewDesc1"
      },
      {
        hero: "/images/home/review_hero_2.jpg",
        avatar: "/images/home/gb/review_1.svg",
        title: "reviewTitle2",
        review: "reviewDesc2"
      }
    ]
  };

  const { t } = useTranslation("home");
  const { country } = useContext(LocaleContext);

  return (
    <Box
      sx={{
        ".carousel .control-dots": {
          "padding-inline-start": 0
        }
      }}
    >
      <Box variant="layout.large">
        <Heading as="h2" variant="h2" pb={3}>
          {t("gudogReviews")}
        </Heading>
      </Box>

      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={false}
        width="100%"
      >
        {reviews[country].map((val, index) => {
          return (
            <Box key={index} width={1} py={["112px", "112px", "186px"]}>
              <CoverImage
                src={val.hero}
                darken={0}
                alt={`Gudog Review ${index}`}
              />
              <Box
                width={[1, 1, 1 / 2]}
                px={[2, 2, 0]}
                mx={[0, 0, "auto"]}
                sx={{
                  position: "relative",
                  textAlign: "center",
                  color: "samoyedWhite"
                }}
              >
                <Heading as="p" variant="h2" pb={1} fontSize={5}>
                  {t(val.title)}
                </Heading>

                <ReviewStars sx={{ pb: 2 }} />

                <Text
                  as="p"
                  fontSize={2}
                  lineHeight="body"
                  sx={{
                    whiteSpace: "pre-line"
                  }}
                >
                  <Trans i18nKey={`home:${val.review}`} />
                </Text>
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
}
