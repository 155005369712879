import { Flex } from "rebass/styled-components";
import { CoverImage } from "./common";

interface Props {
  children: any;
  variant: string;
  imgsrc: string;
  height?: string | string[];
  imgAlt?: string;
  darken: number;
}

export default function Hero(props: Props) {
  const { children, variant, imgsrc, imgAlt, height, darken } = props;

  return (
    <Flex flexWrap="wrap" variant={`hero.${variant}`} sx={{ height }}>
      <CoverImage
        src={imgsrc}
        alt={imgAlt}
        shouldLazyLoad={false}
        priority
        darken={darken}
      />
      <Flex variant="hero.flexChildren">{children}</Flex>
    </Flex>
  );
}

Hero.defaultProps = {
  variant: "default",
  darken: 0.25
};
